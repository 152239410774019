import request from '@/utils/request'


// 查询短剧列表
export function listVideo(query) {
  return request({
    url: '/video/videos/list',
    method: 'get',
    params: query
  })
}

// 查询短剧分页
export function pageVideo(query) {
  return request({
    url: '/video/videos/page',
    method: 'get',
    params: query
  })
}

export function listSelect(query) {
  return request({
    url: '/video/videos/listSelect',
    method: 'get',
    params: query
  })
}

// 查询短剧详细
export function getVideo(data) {
  return request({
    url: '/video/videos/detail',
    method: 'get',
    params: data
  })
}

// 新增短剧
export function addVideo(data) {
  return request({
    url: '/video/videos/add',
    method: 'post',
    data: data
  })
}

// 修改短剧
export function updateVideo(data) {
  return request({
    url: '/video/videos/edit',
    method: 'post',
    data: data
  })
}

// 删除短剧
export function delVideo(data) {
  return request({
    url: '/video/videos/delete',
    method: 'post',
    data: data
  })
}


export function editSort(data) {
  return request({
    url: '/video/videos/editSort',
    method: 'post',
    data: data
  })
}

export function editTrendingSort(data) {
  return request({
    url: '/video/videos/editTrendingSort',
    method: 'post',
    data: data
  })
}

// 导入用户
export function importData (data) {
  return request({
    url: '/video/videos/importData',
    method: 'post',
    data: data
  })
}